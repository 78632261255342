$(document).ready(function() {

    /* ============= Owl Slider =============== */

    //$("#home-slider").owlCarousel({
    //
    //    navigation : false, // Show next and prev buttons
    //    //slideSpeed : 300,
    //    paginationSpeed : 400,
    //    singleItem:true,
    //    autoPlay : 5000,
    //    stopOnHover : true,
    //    transitionStyle : "fade"
    //
    //    // "singleItem:true" is a shortcut for:
    //    // items : 1,
    //    // itemsDesktop : false,
    //    // itemsDesktopSmall : false,
    //    // itemsTablet: false,
    //    // itemsMobile : false
    //});

    /* =========== */

    //var owl_activity = $("#owl-activity");
    //
    //owl_activity.owlCarousel({
    //    items : 3, //10 items above 1000px browser width
    //    itemsDesktop : [1000,3], //5 items between 1000px and 901px
    //    itemsDesktopSmall : [900,2], // betweem 900px and 601px
    //    itemsTablet: [600,1], //2 items between 600 and 0
    //    itemsMobile : [400,1], // itemsMobile disabled - inherit from itemsTablet option
    //    //pagination : false,
    //    lazyLoad: true,
    //    scrollPerPage: true
    //});
    //
    //// Custom Navigation Events
    //$(".next-activity").click(function(e){
    //    e.preventDefault();
    //    owl_activity.trigger('owl.next');
    //});
    //$(".prev-activity").click(function(e){
    //    e.preventDefault();
    //    owl_activity.trigger('owl.prev');
    //});

    /* ============= Scroll Up =============== */

//    $(window).scroll(function()
//    {
//        if ($(this).scrollTop()>100)
//            $('.up').fadeOut();
//        else
//            $('.up').fadeIn();
//    });

    // Scroll to Top
    $('.up').click(function() {
        $('html, body').animate({
            scrollTop: $('body').offset().top
        }, 500);
        return false;
    });

    /* ===== Auto height columns ====== */

    function setEqualHeight(columns)
    {
        var tallestcolumn = 0;
        columns.each(function() {
            currentHeight = $(this).height();
            if(currentHeight > tallestcolumn)
                tallestcolumn = currentHeight;
        });
        columns.height(tallestcolumn);
    }

    setEqualHeight($(".activity-block .item"));
    setEqualHeight($(".owl-wrapper .item"));

    //var time;
    //window.onresize = function(e){
    //    if (time)
    //        clearTimeout(time);
    //    time = setTimeout(function(){
    //        setEqualHeight($(".owl-wrapper .item"));;
    //    },1000);
    //}


    /* ==============================================
    baguetteBox
    =============================================== */
    baguetteBox.run('.photo-box');



    /* =========== */

    //var owl_personal = $("#owl-personal");
    //
    //owl_personal.owlCarousel({
    //    items : 5, //10 items above 1000px browser width
    //    itemsDesktop : [1000,5], //5 items between 1000px and 901px
    //    itemsDesktopSmall : [900,3], // betweem 900px and 601px
    //    itemsTablet: [600,2], //2 items between 600 and 0
    //    itemsMobile : [400,1], // itemsMobile disabled - inherit from itemsTablet option
    //    pagination : false,
    //    lazyLoad: true,
    //    scrollPerPage: true
    //});
    //
    //$(".next-personal").click(function(e){
    //    e.preventDefault();
    //    owl_personal.trigger('owl.next');
    //});
    //$(".prev-personal").click(function(e){
    //    e.preventDefault();
    //    owl_personal.trigger('owl.prev');
    //});

    /* =========== */

    //var owl_news = $("#owl-news");
    //
    //owl_news.owlCarousel({
    //    items : 4, //10 items above 1000px browser width
    //    itemsDesktop : [1000,4], //5 items between 1000px and 901px
    //    itemsDesktopSmall : [900,3], // betweem 900px and 601px
    //    itemsTablet: [600,1], //2 items between 600 and 0
    //    itemsMobile : false, // itemsMobile disabled - inherit from itemsTablet option
    //    pagination : false,
    //
    //    lazyLoad: true,
    //    scrollPerPage: true
    //});
    //
    //$(".next-news").click(function(e){
    //    e.preventDefault();
    //    owl_news.trigger('owl.next');
    //});
    //$(".prev-news").click(function(e){
    //    e.preventDefault();
    //    owl_news.trigger('owl.prev');
    //});


    /* ============= responsiveTabs =============== */

    //$('#responsiveTabsDemo').responsiveTabs({
    //    startCollapsed: 'accordion'
    //});

    /* ============= iCheck =============== */

    //$('input.icheck').iCheck({
    //    checkboxClass: 'icheckbox_square-blue',
    //    radioClass: 'icheckbox_square-blue',
    //    increaseArea: '20%' // optional
    //});
    //
    //$('#btn_recal_good').click(function(e){
    //    e.preventDefault();
    //    $(this).addClass('btn-default');
    //    $('#btn_recal_bad').removeClass('btn-default');
    //    $('#input-1').iCheck('check');
    //});
    //
    //$('#btn_recal_bad').click(function(e){
    //    e.preventDefault();
    //    $(this).addClass('btn-default');
    //    $('#btn_recal_good').removeClass('btn-default');
    //    $('#input-2').iCheck('check');
    //});

    /* ============= pickadate =============== */

    //$('.datepicker1').pickadate({
    //    formatSubmit: 'yyyy/mm/dd'
    //
    //    //selectYears: true,
    //    //selectMonths: true
    //    //selectYears: 4
    //
    //    //disable: [
    //    //    1, 4, 7
    //    //]
    //});

    //$('.timepicker1').pickatime({
    //    clear: '',
    //    //formatLabel: 'H:i',
    //    format: 'H:i',
    //    min: [8,30],
    //    max: [18,0]
    //
    //    //disable: [
    //    //    [0,30],
    //    //    [2,0],
    //    //    [8,30],
    //    //    [9,0]
    //    //]
    //
    //});

    /* ============= inputMask =============== */

    //$(":input").inputmask();

    /* ============= DatePicker =============== */

//    $('.datepicker_birth_day').pickadate({
//        formatSubmit: 'yyyy.mm.dd',
//        hiddenName: true,
////            format: 'yyyy/mm/dd',
//        selectMonths: true,
////            selectYears: true,
//        selectYears: 70,
//        min: new Date(1930,1,1),
//        max: true
//    });
//
//    $('.datepicker2').pickadate({
//        format: 'yyyy.mm.dd',
//        hiddenName: true,
//        selectMonths: true,
//        selectYears: 20
//    });

    /* ============= dataTables =============== */

    //var DataTableLanguage =  {
    //    "processing": "Подождите...",
    //    "search": "Поиск:",
    //    "lengthMenu": "Показать _MENU_ записей",
    //    "info": "Записи с _START_ до _END_ из _TOTAL_ записей",
    //    "infoEmpty": "Записи с 0 до 0 из 0 записей",
    //    "infoFiltered": "(отфильтровано из _MAX_ записей)",
    //    "infoPostFix": "",
    //    "loadingRecords": "Загрузка записей...",
    //    "zeroRecords": "Записи отсутствуют.",
    //    "emptyTable": "В таблице отсутствуют данные",
    //    "paginate": {
    //        "first": "Первая",
    //        "previous": "Предыдущая",
    //        "next": "Следующая",
    //        "last": "Последняя"
    //    },
    //    "aria": {
    //        "sortAscending": ": активировать для сортировки столбца по возрастанию",
    //        "sortDescending": ": активировать для сортировки столбца по убыванию"
    //    }
    //};
    //
    //$('#dataTable').DataTable({
    //    'language': DataTableLanguage,
    //    //"pagingType": "numbers"
    //    "pagingType": "simple_numbers",
    //    //"scrollX": true
    //
    //    //https://datatables.net/reference/option/columns.orderable
    //    "columnDefs": [
    //        { "orderable": false, "targets": 4 }
    //    ]
    //});
    //
    //$('#dataTable2').DataTable({
    //    'language': DataTableLanguage,
    //    "pagingType": "simple_numbers"
    //});

    /* ============= other =============== */

    //$('#record_text').hide();
    //
    //$( "#record_btn" ).click(function(e){
    //    e.preventDefault();
    //    $('#record_text').fadeToggle();
    //});


    /* Contacts */
    //$('.contacts table').hide();
    //
    //$( ".contacts .city" ).parent().toggle(function() {
    //    $( this ).next().fadeIn();
    //}, function() {
    //    $( this ).next().fadeOut();
    //});

});